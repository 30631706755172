@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Permanent+Marker&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d2dae2;
}

h1 {
  font-family: "Permanent Marker", cursive;
  font-size: 40px;
}

h2 {
  font-size: 32px;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  font-weight: 600;
}

small {
  font-size: 14px;
  font-weight: 300;
}
