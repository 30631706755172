header {
  display: flex;
  align-items: center;
  border: 1px solid black;
  box-shadow: 3px 3px 0px 0px black;
  padding: 12px 20px 12px 20px;
  margin: 16px;
  background-color: white;
}

header h2 {
  font-family: "Permanent Marker", cursive;
}

header h1,
header h2,
header h4 {
  margin: 0;
}

header ul {
  display: flex;
  list-style: none;
  margin-left: 120px;
}

header li {
  color: #7b61ff;
  margin-right: 24px;
}

header li a {
  text-decoration: none;
  text-transform: uppercase;
}

header li a:hover {
  text-decoration: underline;
}
