@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Permanent+Marker&display=swap);
body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d2dae2;
}

h1 {
  font-family: "Permanent Marker", cursive;
  font-size: 40px;
}

h2 {
  font-size: 32px;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  font-weight: 600;
}

small {
  font-size: 14px;
  font-weight: 300;
}

main {
  display: flex;
  margin: 16px;
}

main #create-space {
  margin-left: 24px;
}

header {
  display: flex;
  align-items: center;
  border: 1px solid black;
  box-shadow: 3px 3px 0px 0px black;
  padding: 12px 20px 12px 20px;
  margin: 16px;
  background-color: white;
}

header h2 {
  font-family: "Permanent Marker", cursive;
}

header h1,
header h2,
header h4 {
  margin: 0;
}

header ul {
  display: flex;
  list-style: none;
  margin-left: 120px;
}

header li {
  color: #7b61ff;
  margin-right: 24px;
}

header li a {
  text-decoration: none;
  text-transform: uppercase;
}

header li a:hover {
  text-decoration: underline;
}

#toolbox {
  height: 80vh;
  width: 280px;
  background-color: white;
  border: 1px solid black;
  box-shadow: 3px 3px 0px 0px black;
}

canvas {
  height: 58vh;
  width: 80vw;
  background-color: white;
  border: 1px solid black;
  box-shadow: 3px 3px 0px 0px black;
}

#scrubspace {
  height: 20vh;
  width: 80vw;
  margin-top: 2vh;
  background-color: white;
  border: 1px solid black;
  box-shadow: 3px 3px 0px 0px black;
}

